<template>
	<DialogBase
		:dialogWidth="900"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>User Settings</template>

		<div class="pa-2 mt-4">
			<v-card class="ma-2">
				<v-card-title class="transparent-2 mb-2">
					Primary Setting
				</v-card-title>
				<v-card-text>
					<v-form
						class="user-setting-form"
						@submit.prevent
						v-model="isValidForm"
						ref="from"
					>
						<FormMaker
							:schema="primary_setting_form_structure"
							:model="form_data"
						></FormMaker>
					</v-form>
				</v-card-text>
			</v-card>
			<v-card class="ma-2">
				<v-card-title class="transparent-2 mb-2">
					Cross Sale Setting
				</v-card-title>
				<v-card-text>
					<v-form
						class="user-setting-form"
						@submit.prevent
						v-model="isValidForm"
						ref="from"
					>
						<FormMaker
							:schema="cross_sale_setting_form_structure"
							:model="form_data"
						></FormMaker>
					</v-form>
				</v-card-text>
			</v-card>
		</div>
		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">
				Save Settings
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { required, minLen } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null }
		},
		data() {
			return {
				isValidForm: false,
				form_data: {
					is_quote_complete_auto_emailing_enabled: false,
					is_quote_timeout_auto_emailing_enabled: false,
					is_quote_timeout_auto_emailing_time: null,
					is_quote_complete_auto_emailing_enabled_cross_sale: false,
					is_quote_timeout_auto_emailing_enabled_cross_sale: false,
					is_quote_timeout_auto_emailing_time_cross_sale: null
				}
			};
		},
		mounted() {
			this.form_data = this.$store?.getters["auth/user_settings"]?.payload;
		},
		computed: {
			primary_setting_form_structure() {
				let structure = {
					is_quote_complete_auto_emailing_enabled: {
						type: "switch",
						name: "is_quote_complete_auto_emailing_enabled",
						label: `Auto Send Quote Email/MMS On Complete: ${
							this.form_data?.is_quote_complete_auto_emailing_enabled
								? "Yes"
								: "No"
						}`,
						...this.$helpers.formFieldsBasicLayout,
						hideDetails: false,
						inset: true,
						rules: []
					},
					is_quote_timeout_auto_emailing_enabled: {
						type: "switch",
						name: "is_quote_timeout_auto_emailing_enabled",
						label: `Auto Send Quote Email/MMS On Timeout: ${
							this.form_data?.is_quote_timeout_auto_emailing_enabled
								? "Yes"
								: "No"
						}`,
						...this.$helpers.formFieldsBasicLayout,
						hideDetails: false,
						inset: true,
						rules: []
					},
					is_quote_timeout_auto_emailing_time: {
						type: "number",
						name: "is_quote_timeout_auto_emailing_time",
						label: "Timeout Wait Time",
						prefix: "Min.",
						...this.$helpers.formFieldsBasicLayout,
						hidden: !!!this.form_data?.is_quote_timeout_auto_emailing_enabled,
						rules: [required()]
					}
				};

				return structure;
			},
			cross_sale_setting_form_structure() {
				let structure = {
					is_quote_complete_auto_emailing_enabled_cross_sale: {
						type: "switch",
						name: "is_quote_complete_auto_emailing_enabled",
						label: `Auto Send Quote Email/MMS On Complete: ${
							this.form_data?.is_quote_complete_auto_emailing_enabled_cross_sale
								? "Yes"
								: "No"
						}`,
						...this.$helpers.formFieldsBasicLayout,
						hideDetails: false,
						inset: true,
						rules: []
					},
					is_quote_timeout_auto_emailing_enabled_cross_sale: {
						type: "switch",
						name: "is_quote_timeout_auto_emailing_enabled",
						label: `Auto Send Quote Email/MMS On Timeout: ${
							this.form_data?.is_quote_timeout_auto_emailing_enabled_cross_sale
								? "Yes"
								: "No"
						}`,
						...this.$helpers.formFieldsBasicLayout,
						hideDetails: false,
						inset: true,
						rules: []
					},
					is_quote_timeout_auto_emailing_time_cross_sale: {
						type: "number",
						name: "is_quote_timeout_auto_emailing_time",
						label: "Timeout Wait Time",
						prefix: "Min.",
						...this.$helpers.formFieldsBasicLayout,
						hidden: !!!this.form_data
							?.is_quote_timeout_auto_emailing_enabled_cross_sale,
						rules: [required()]
					}
				};

				return structure;
			}
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let payload = {
					...this.form_data,
					is_quote_complete_auto_emailing_enabled: !!this.form_data
						?.is_quote_complete_auto_emailing_enabled
						? 1
						: 0,
					is_quote_timeout_auto_emailing_enabled: !!this.form_data
						?.is_quote_timeout_auto_emailing_enabled
						? 1
						: 0,
					is_quote_complete_auto_emailing_enabled_cross_sale: !!this.form_data
						?.is_quote_complete_auto_emailing_enabled_cross_sale
						? 1
						: 0,
					is_quote_timeout_auto_emailing_enabled_cross_sale: !!this.form_data
						?.is_quote_timeout_auto_emailing_enabled_cross_sale
						? 1
						: 0
				};

				await this.$store
					.dispatch("auth/updateUserSettings", { payload })
					.then(res => {
						this.showMessageFromResponseDta(res.data);
						if (res.data.code == 200) {
							this.closeDialog();
						}
					});
				return true;
			}
		}
	};
</script>

<style lang="scss">
	.user-setting-form {
		.input-custom-title {
			display: none;
		}
	}
</style>
